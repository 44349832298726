var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payments-column mx-auto mt-auto"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t('choosePaymentMethod')))]), _vm.stripeAvailable ? _c('button', {
    staticClass: "btn btn-primary payment-button payment-item with-icon",
    class: {
      disabled: _vm.disabled
    },
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.stripeFn();
      }
    }
  }, [_vm._m(0), _vm._v(" " + _vm._s(_vm.$t('card')) + " ")]) : _vm._e(), _vm.paypalAvailable ? _c('button', {
    staticClass: "btn payment-item paypal-checkout payment-button",
    class: {
      disabled: _vm.disabled
    },
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.paypalFn();
      }
    }
  }, [_vm._v("   "), _c('img', {
    attrs: {
      "src": require("@/assets/images/paypal-checkout.png"),
      "alt": _vm.$t('paypal')
    }
  }), _vm._v("  ")]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon color credit-card-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.creditCardIcon)
    }
  });

}]

export { render, staticRenderFns }